import React, { useState, useEffect } from "react";
import './App.css'; // Assuming your CSS is in a file named App.css

const LOGO_URL = "https://kaanch.com/wp-content/uploads/2023/07/cropped-1-1-1024x341.png";

function KaanchApp() {
  const [accountAddress, setAccountAddress] = useState("");
  const [balance, setBalance] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isKaanchAvailable, setIsKaanchAvailable] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.kaanch) {
        setIsKaanchAvailable(true);
        window.kaanch.isConnected().then(connected => {
          setIsConnected(connected);
          if (connected) {
            window.kaanch.account().then(account => {
              setAccountAddress(account);
              fetchBalance(account);
            });
          }
        });
      } else {
        console.error("kaanch object not found on window");
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const fetchBalance = async (address) => {
    try {
      const response = await fetch(`https://data.all-balance.kaanch.network/?address=${address}`);
      const data = await response.json();
      const balanceNumber = parseInt(data.balance, 16) / Math.pow(10, 18);
      setBalance(balanceNumber.toFixed(18));
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  const handleConnectionRequest = async () => {
    if (window.kaanch && !isConnected) {
      await window.kaanch.connect();
      window.location.reload();
    } else {
      console.error("kaanch object not found on window or already connected");
    }
  };

  const handleApiRequest = async () => {
    if (balance >= 1) {
        console.log("Balance is sufficient, no need to send a transaction.");
        return;
    }

    try {
      const requestData = {
        toAddress: accountAddress,
        amount: "10"
      };

      const response = await fetch("https://send-kaanch-without-pvtkey.rkgroup.workers.dev", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const data = await response.json();
      console.log("Transaction response:", data);
    } catch (error) {
      console.error("Failed to send transaction:", error);
    }
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <img src={LOGO_URL} alt="Logo" className="logo" />
      </header>
      <main className="app-main">
        <button onClick={handleConnectionRequest}>
          {isConnected ? `${accountAddress.substring(0, 4)}...${accountAddress.substring(accountAddress.length - 4)}` : 'Connect to Kaanch'}
        </button>
        {isConnected && <p>Current Balance: {balance !== null ? balance : 'Loading...'} KNCH</p>}
        {accountAddress && (
          <button onClick={handleApiRequest} disabled={balance >= 1}>
            Send API Request
          </button>
        )}
      </main>
      <footer className="app-footer">
        <p>© 2024 Kaanch Inc. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default KaanchApp;
